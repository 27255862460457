.product-page__wrapper {
  display: flex;
  margin-bottom: 60px;
}

.product-page__img {
  width: 70%;
  object-fit: cover;
}

.image-container {
  width: 50%;
}

.product-info-container {
  padding-bottom: 60px;
  width: 50%;
}

.product-page__title {
  font-weight: 200;
}

.product-text {
  margin: 0;
  font-weight: 600;
}

.column {
  margin: 0;
  list-style-type: circle;
  padding-bottom: 10px;
}

.column__list {
  color: #f68632;
  padding: 2px;
  width: 100%;
}

span {
  color: black;
}

.product-page__price__buy {
  color: #222;
  background-color: #f68632;
}

.title {
  font-weight: 300;
  margin: 0px;
}

.cover-list {
  background-color: #f5f5f5f5;
  border-radius: 50px;
  padding-bottom: 10px;
}

.cover-list li {
  padding: 0;
}

.product-page__day {
  font-size: 18px;
  margin-left: 5px;
}

.cover-count {
  display: flex;
  justify-content: center;
  padding-right: 50px;
  padding-top: 10px;
  font-size: 30px;
}

.cover-count-price {
  position: absolute;
  margin-top: 110px;

  font-size: 17px;
  width: 300px;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: none;
  background-color: #fff;
  margin-top: 40px;
  cursor: pointer;
  color: #222;
}

@media only screen and (max-width: 785px) {
  .product-page__title {
    font-size: 17px;
  }
  .product-text {
    font-size: 15px;
  }
  .column {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 610px) {
  .product-page__wrapper {
    display: flex;
    flex-direction: column;
  }
  .image-container {
    width: 80%;
  }

  .product-info-container {
    width: 100%;
  }
}
