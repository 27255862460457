
  
  /* .container {
    max-width: 700px;
    margin: 40px auto;
    padding: 20px;
  }
   */
  .main-title-rock {
    /* font-size: 1.8rem; */
    text-align: center;
    color: #1a1a1a;
    /* margin-bottom: 20px; */
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  
  .intro {
    /* font-size: 1rem; */
    text-align: center;
    color: #666;
    margin-top: -5px;
  }
  
  .point {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  .point-title {
    /* font-size: 1.3rem; */
    color: #de682d;
    margin-bottom: 10px;

    /* font-weight: 600; */
  }
  
  .point-list, .details-list {
    list-style: none;
    padding-left: 0;
    margin: 10px 0;
    font-weight: 300

  }
  
  .point-list li, .details-list li {
    /* font-size: 0.95rem; */
    color: #333;
    padding: 5px 0;
  }
  
  .details-title {
    /* font-size: 1rem; */
    font-weight: 600;
    color: #555;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .point-description {
    /* font-size: 0.95rem; */
    color: #444;
    line-height: 1.6;
    margin-top: 10px;
    font-weight: 300
  }
  
  @media (min-width: 768px) {
    .container {
      padding: 40px;
    }
  
    .main-title {
      font-size: 2rem;
    }
  
    .point {
      padding: 25px;
    }
  }
  


.schedule-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    text-align: center;
  }
  
  .schedule-title {
    font-size: 1.8rem;
    color: #1a1a1a;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  
  .day-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .day-title {
    font-size: 1.4rem;
    color: #de682d;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .session {
    font-size: 1rem;
    color: #333;
    margin: 10px 0;
    padding: 10px;
    background-color: #f9eae0;
    border-radius: 6px;
  }
  
  .group-time {
    font-weight: 500;
  }


  .register-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #de682d; 
    color: #fff; 
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .register-button:hover {
    background-color:#c35b28; 
    transform: translateY(-2px); 
  }
  
  .register-button:active {
    background-color: #c35b28;
    transform: translateY(0); 
  }
  

  .price {
    font-size: 1rem;
    color: #555;
    font-weight: 500;
    margin: 8px 0 12px; /* Отступы сверху и снизу */
  }


  /* Стили для галереи */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.gallery-image {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
}
.gallery-image-instructor {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  object-fit: cover;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(2, 1fr); /* 2 колонки на планшетах и меньше */
  }
}

@media (max-width: 480px) {
  .image-gallery {
    grid-template-columns: 1fr; /* 1 колонка на мобильных устройствах */
  }
}