.error-page-container {
  margin-top: 100px;
  margin-bottom: 80px;
}

.error-page-title {
  display: flex;
  flex-direction: row;
}

.error-page-error {
  font-size: 15px;
  margin-top: -20px;
  width: 300px;
}

.error-page-discripshion {
  width: 500px;
  margin-top: 40px;
}

.error-page-btn {
  border: none;
  outline: none;
  background-color: #fff;
}

.error-page-btn {
  margin-top: 10px;
  cursor: pointer;
  letter-spacing: 0.4px;
  font-size: 16px;
}
