.add-to-cart__rent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.rent-items-price {
  padding-left: 15px;
  color: white;
}

.rent-items__button {
  display: inline-block;
  margin-bottom: 7px;
}

.md {
  margin-bottom: -8px;
}

.add-to-cart-cover {
  width: 100%;
}

.add-to-cart-rent {
  margin-top: 10px;
  background-color: #f87736;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  transition: transform 500ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rent-day {
  font-weight: 600;
}

@media only screen and (max-width: 400px) {
  .rent-day {
    font-size: 13px;
  }
}
