.order-item {
  margin-right: 100px;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-item__price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.order-items__img {
  width: 140px;
}

.order-item__title {
  font-size: 20px;
}

.order-item__delete {
  margin-left: 5px;
  cursor: pointer;
  color: #9f9d9df5;
}

@media only screen and (max-width: 790px) {
  .order-item {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 530px) {
  .order-item {
    margin-right: 5px;
    margin-left: -25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-items__img {
    width: 100px;
  }

  .order-item__title {
    font-size: 12px;
  }
  .order-item__price {
    font-size: 17px;
    margin-left: 20px;
  }
}
