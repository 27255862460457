.discount-items__detail {
}

.discount-items {
  width: 200px;
  margin-bottom: 20px;
  background: #fff;
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
  text-align: center;
  border: solid 2px #f5f5f5f5;
  height: 300px;
}

.discount__title {
  display: block;
  margin-top: 15px;
  font-size: 10px;
}
.discount__desc {
  display: block;
  margin-top: 15px;
  font-size: 10px;
}

.discount-product {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

@media (max-width: 1000px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
@media (max-width: 900px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
@media (max-width: 750px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
@media (max-width: 500px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .discount-items {
    width: 180px;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border: solid 2px #f5f5f5f5;
    height: 280px;
  }
}
@media (max-width: 460px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .discount-items {
    width: 160px;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border: solid 2px #f5f5f5f5;
    height: 280px;
  }
}
@media (max-width: 415px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .discount-items {
    width: 140px;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border: solid 2px #f5f5f5f5;
    height: 280px;
  }
}
@media (max-width: 415px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .discount-items {
    width: 140px;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border: solid 2px #f5f5f5f5;
    height: 280px;
  }
}
@media (max-width: 365px) {
  .discount-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .discount-items {
    width: 120px;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border: solid 2px #f5f5f5f5;
    height: 280px;
  }
}
