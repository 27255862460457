@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap");
.App {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
/* padding-left: 40px; */
}

a {
  color: #222;
  text-decoration: none;
  cursor: pointer;
}
