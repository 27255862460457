/* Контейнер для карточек */
.sale-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
   margin-top: 10px;
}

.sale-item {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: calc(25% - 15px); 
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sale-item:hover {
  transform: translateY(-5px);
}

.sale-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 2px solid #f2f2f2;
}

.sale-info {
  padding: 20px;
  text-align: center;
}

.sale-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
  line-height: 1.4;
 
}

.highlight-sale {

  color: #3285a8;
  font-weight: 700;
  background-color: #e6f2f9;
  padding: 2px 8px;
  border-radius: 4px;

 
}

.highlight-rent {
  color: #f68632;
  font-weight: 700;
  background-color: #fff4e0;
  padding: 2px 8px;
  border-radius: 4px;
}

/* Адаптивность для мобильных устройств */

/* Для планшетов и больших экранов: 3 элемента в ряд */
@media (max-width: 1024px) {
  .sale-item {
    width: calc(50% - 10px); 
  }
  .sale-item:hover {
    transform: none;
  }
}

@media (max-width: 768px) {
  .sale-item {
    margin-bottom: 20px; 
  }
  .sale-title {
    font-size: 14px;
  }
  .sale-info {
    padding: 10px;

  }
}
@media (max-width: 714px) {

  .sale-info {
    padding: 25px;
  }
}
@media (max-width: 579px) {

  .sale-info {
    padding: 10px;
  }
}
@media (max-width: 520px) {

  .sale-title {
    font-size: 12px;
  }
}
@media (max-width: 470px) {

  .sale-title {
    font-size: 10px;
  }
  /* .sale-info {
    padding: 20px 5px;
  } */
}
@media (max-width: 392px) {

  /* .sale-title {
    font-size: 12px;
  }
  .sale-info {
    padding: 10px 0px;
  } */
}



.header-section-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 80px 20px 0px 40px ;
}

.main-title-alt {
  font-size: 40px;
  font-weight: 700;
  color: #333; 
  margin: 0;
  line-height: 1.4;
}


.orange-line {
  font-weight: 700;
  display: inline-block;
  border-bottom: 4px solid #f68632; 
  padding: 0 4px;
}
.rental-name {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
  letter-spacing: 1px;
  
}

.orange-box {
  background-color: #f68632;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
}

.sub-title-alt {
  font-size: 18px;
  color: #555;
  margin-top: 20px;
  line-height: 1.6;
}


.highlight-alt {
  font-weight: 300;
 
}


@media (max-width: 768px) {
  .main-title-alt {
    font-size: 32px;
  }

  .sub-title-alt {
    font-size: 16px;
  }

  .orange-line {
    font-size: 16px; 
    padding: 0 2px; 
  }

  .orange-box {
    font-size: 14px; 
    padding: 2px 4px; 
  }
}

@media (max-width: 480px) {
  .main-title-alt {
    font-size: 28px; 
  }

  .sub-title-alt {
    font-size: 14px; 
    /* display: flex; */
  }

  .orange-line {
    font-size: 15px; 
    
  }

  .orange-box {
    font-size: 12px; 
  }
}

.home-page-product {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

@media (max-width: 900px) {
  .home-page-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }
}

@media (max-width: 500px) {
  .home-page-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.title {
  /* padding-top: 15px; */
  padding-bottom: 10px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.categories-item {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px 20px;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 500ms ease;
  color: #222;
  position: relative;
  display: inline-block;
}

.categories-item-img:hover {
  border: 1px solid #f68632;
  background-color: #fff;
  position: relative;
  display: inline-block;
}

.categories-item-title {
  font-weight: 600;
}

.categories-item-img {
  width: 160px;
  border: 1px solid #000;
}

.categories {
  display: flex;
  margin-bottom: 60px;
  margin-left: -25px;
  height: 300px;
}

.home-page__container {
  /* margin-top: 10px; */
margin-bottom: 10px;
}

.home-page__container-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 15px;
  margin-bottom: -15px; */

}
@media only screen and (max-width: 1104px) {
  .categories {
    display: flex;
    margin-bottom: -20px;
    left: -35px;
    margin-top: 25px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 60px;
    height: 300px;
    
  }
  .arrow-cover {
    overflow: hidden;
  }
}

.arrow-cover {
  position: absolute;
  right: 100px;
  margin-top: 25px;
}

.arrow-cover-right {
  margin-left: 50px;
}

.arrow-categories {
  width: 35px;
  height: 35px;
  background-color: #ffffffff;
  border: 2px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  bottom: 20px;
  right: 50px;
  /* margin: auto; */
  cursor: pointer;
  transition: all 500ms ease;
  opacity: 0.8;
  z-index: 2000;
}

.arrow-categories-left {
  width: 35px;
  height: 35px;
  background-color: #ffffffff;
  border: 2px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  bottom: 0;
  right: 0px;
  /* margin: auto; */
  cursor: pointer;
  transition: all 500ms ease;
  opacity: 0.8;
  z-index: 2000;
}

.arrow-categories:hover {
  background-color: #f6f6f6f5;
  border: 2px solid #f68632;
}

.arrow-categories-left:hover {
  background-color: #f6f6f6f5;
  border: 2px solid #f68632;
}

@media only screen and (max-width: 853px) {
  .categories {
    margin-top: -10px;
  }
  /* .home-page__container-title {
    margin-top: 50px;
  } */
}
@media only screen and (max-width: 614px) {
  .home-page__container-title {
    /* margin-top: 70px; */
  }
}
@media only screen and (max-width: 469px) {
  .home-page__container-title {
    /* margin-top: 80px; */
  }
}

.button-rules {
  background-color: #fff;
  border: 1px solid #f68632;
  margin-top: 20px;
  margin-left: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 15px;
  color: #222;
}

.back-button-cover {
  margin-top: 65px;

}
@media only screen and (max-width: 785px) {
  .back-button-cover {
    margin-top: 65px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 615px) {
  .back-button-cover {
    margin-top: 80px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 468px) {
  .back-button-cover {
    margin-top: 85px;
    margin-bottom: 10px;
  }
}
.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
}

/* .slick-prev {

  left: -100px;
}

.slick-next {
  right: 50px;
} */


/* Общий стиль для секции */
.header-section {
    text-align: center;
    margin-top: 50px;
    font-family: 'Arial', sans-serif;
}

/* Основной заголовок */
.main-title {
    font-size: 48px;
    font-weight: bold;
    color: #f68632;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
}

/* Общий фон для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Контейнер модального окна */
.modal-content {
  width: 50%; /* Половина экрана */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Кнопка закрытия */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Контейнер для изображений */
.images-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  
}

/* Изображения */
.modal-image {
  width: 48%; /* Два изображения, по половине ширины контейнера */
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

/* Кнопка открытия модального окна */
.open-modal-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.open-modal-button:hover {
  background-color: #0056b3;
}

/* App.css */
.service-table {
  width: 100%;
  border-collapse: collapse;
  /* margin: 20px 0; */
  font-size: 16px;
  text-align: left;
}

.service-table th,
.service-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.service-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.service-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.service-table tr:hover {
  background-color: #f1f1f1;
}

.images-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

/* Изображения */
.modal-image {
  width: 48%; /* Два изображения, по половине ширины контейнера */
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

@media (max-width: 775px) {
  .images-container {
    flex-direction: column;
    align-items: center; /* Optional: To center the images vertically */
    width: 90%; /* You can adjust this to control the container width */
    margin-top: 10px; /* Adjust the top margin for mobile */
  }

  .modal-image {
    width: 80%; /* Adjust image width on mobile */
    height: auto; /* Ensure the height adjusts proportionally */
    margin-bottom: 10px; /* Add margin between images */
  }
}
