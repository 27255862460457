.choose-cover {
  display: flex;
}

.choose-checkin {
  color: #de682d;
  margin-top: 10px;
}

.choose-item {
  display: flex;
}

h5 {
  margin-top: 15px;
  margin-left: 10px;
}

.choose-item-description {
  margin-top: -10px;
  /* width: 570px; */

  font-weight: 600;
  color: rgba(34, 34, 34, 0.788);
  font-size: 15px;
}

.choose-cover-advice {
  width: 570px;
  margin-right: 10px;
}

@media (max-width: 707px) {
  .choose-cover {
    display: flex;
    flex-direction: column;
  }
  .choose-cover-advice {
    width: 95%;
  }
  .choose-item-description {
    font-size: 12px;
  }
}
