.lake-route-page-cover {
  display: flex;
  flex-direction: column;
}
.lake-route-page-cover-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.route-page-img {
  width: 250px;
  margin-left: 10px;
}

.lake-route-page-cover-desc {
  width: 100%;
}

.lake-route-page-cover-title {
  font-weight: 600;
}

.lake-route-page-points {
  font-weight: 600;
  margin-bottom: -20px;
}

.lake-route-page-url {
  color: rgb(35, 177, 80);
  font-weight: 600;
}

@media (max-width: 1057px) {
  .route-page-img {
    width: 200px;
  }
}
@media (max-width: 896px) {
  .route-page-img {
    width: 160px;
  }
}
@media (max-width: 736px) {
  .lake-route-page-cover-img {
    width: 70%;
    margin-left: 80px;
  }
  @media (max-width: 736px) {
    .route-page-img {
      width: 150px;
    }
  }
}
@media (max-width: 513px) {
  .lake-route-page-cover-img {
    width: 100%;

    margin-left: 5px;
  }
  @media (max-width: 736px) {
    .route-page-img {
      width: 150px;
    }
  }
}
@media (max-width: 376px) {
  /* .lake-route-page-cover-img {
    width: 100%;

    margin-left: 5px;
  } */
  @media (max-width: 736px) {
    .route-page-img {
      width: 120px;
    }
  }
}
