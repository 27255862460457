body {
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #fdfdfd;
  }
  
  .container {
    /* max-width: 600px; */
    /* margin: 20px auto; */
    /* padding: 20px; */
    line-height: 1.6;
  }
  
  h1 {
    /* text-align: center; */
    color: #f68632;
    font-size: 2rem;
    /* margin-bottom: 20px; */
  }
  
  .section h2 {
    color: #f68632;
    font-size: 1.2rem;
  }
  
  ul {
    padding-left: 20px;
    /* list-style: square; */
  }
/*   
  ul li {
    margin-bottom: 10px;
  } */
  
  ul li span {
    font-size: 0.9rem;
  }
  
  .price {
    /* text-align: center; */
    font-size: 1.1rem;
    margin-top: 20px;
    color: #333;
  }
  
  .price strong {
    color: #f68632;
  }
  

  .swiper-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }