.header {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  width: 100%;
  flex-wrap: wrap;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.wrapper {
  display: flex;
  align-items: center;
}

.location,
.number {
  font-size: 14px;
  margin: 5px 10px;
  text-align: center;
}




.cart {
  margin: 0 10px;
}

@media only screen and (max-width: 785px) {
  .header {
    flex-wrap: wrap;
    padding: 10px;
  }
  .number {
    flex: 1 1 100%;
    text-align: right;
  }
}

@media only screen and (max-width: 654px) {
  .location {
    font-size: 12px;
  }
  .number {
    font-size: 12px;
  }
}

/* @media only screen and (max-width: 615px) {
  .wrapper {
    flex: 1 1 100%;
    justify-content: center;
  }
  .header {
    flex-direction: column;
    align-items: center;
  }
  .icon {
    gap: 10px;
  }
} */

@media only screen and (max-width: 615px) {
  .location {
    display: none;
  }
  .number {
    display: none;
  }
  .cart {
    position: relative;
  }
}

@media only screen and (max-width: 450px) {
  .icon {
    display: none;
  }
}


