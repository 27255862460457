.cover-routes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 10px;
}

.routes-cover-block {
}

.routes-cover-title {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  flex-direction: column;
}

.routes-item-page {
  font-weight: 600;
  font-size: 13px;
  margin-top: -10px;
  text-decoration: underline;
}

.routes-cover-summary {
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.routes-image {
  width: 380px;
}

.routes-title {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 1207px) {
  .routes-image {
    width: 90%;
  }
  .routes-cover-title {
    width: 90%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1106px) {
  .routes-cover-title {
    margin-top: 5px;
  }
}

@media (max-width: 743px) {
  h2 {
    font-size: 20px;
  }
  .routes-cover-title {
    font-size: 15px;
  }
}
@media (max-width: 692px) {
  .routes-cover-title {
    font-size: 12px;
    height: 60px;
  }
  .routes-item-page {
    font-size: 12px;
  }
}

@media (max-width: 630px) {
  .cover-routes {
    display: flex;
    flex-wrap: wrap;
  }
  .routes-image {
    width: 60%;
  }
  .routes-cover-title {
    width: 60%;
    margin-left: 105px;
  }
  .routes-cover-block {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* height: 100vh; */
  }
}
@media (max-width: 591px) {
  .routes-cover-title {
    margin-left: 105px;
  }
}
@media (max-width: 571px) {
  .routes-cover-title {
    margin-left: 98px;
  }
}
@media (max-width: 516px) {
  .routes-cover-title {
    margin-left: 90px;
  }
}
@media (max-width: 488px) {
  .routes-cover-title {
    margin-left: 82px;
  }
}
@media (max-width: 437px) {
  .routes-cover-title {
    margin-left: 75px;
  }
}
@media (max-width: 412px) {
  .routes-cover-title {
    margin-left: 67px;
  }
}
@media (max-width: 372px) {
  .routes-cover-title {
    margin-left: 60px;
  }
}
