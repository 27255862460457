.cart-menu {
  position: absolute;
  top: 35px;
  right: 7px;
  left: -370px;
  background-color: #ebe8e8f5;
  width: 440px;
  padding: 5px 20px;
  z-index: 2;
  border-radius: 15px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cart-menu::-webkit-scrollbar {
  width: 7px;
}

.cart-menu::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 40px;
}

.cart-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media (max-width: 510px) {
  .cart-menu {
    left: -340px;
  }
  .cart-menu {
    width: 400px;
  }
}
@media (max-width: 468px) {
  .cart-menu {
    top: 45px;
  }
}
@media (max-width: 450px) {
  .cart-menu {
    left: -285px;
  }
  .cart-menu {
    width: 345px;
  }
}
@media (max-width: 408px) {
  .cart-menu {
    left: -265px;
  }
  .cart-menu {
    width: 330px;
  }
}

@media (max-width: 388px) {
  .cart-menu {
    left: -235px;
  }
  .cart-menu {
    width: 290px;
  }
}
