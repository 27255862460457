.product-items__price {
  padding-left: 7px;
}

.product-items__oldPrice.discounted {
  font-size: 12px;
}
.product-items__price.discounted {
  font-size: 12px;
}

.product-items__count {
  border: 1px solid white;
  color: #de682d;
  border-radius: 40px;
  background-color: white;
  padding: 1px 5px;
}

.container-items-price {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.add-to-cart-cover {
  width: 100%;
}

.add-to-cart.discounted {
  margin-top: 10px;
  background-color: #f87736;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  transition: transform 500ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.add-to-cart {
  margin-top: 10px;
  background-color: #f87736;

  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  transition: transform 500ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-to-cart-page {
  margin-top: 10px;
  background-color: #f87736;
  width: 200px;
  margin-bottom: 10px;

  transition: transform 500ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-to-cart:hover {
  transform: scale(1.05);
}
.add-to-cart-page:hover {
  transform: scale(1.05);
}

.add-to-cart-title.discounted {
  color: white;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 10px;
}
.add-to-cart-title {
  color: white;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-right: 10px;
}

.add-to-cart-icon {
  display: inline-block;
  vertical-align: middle;
}

.shopping-cart-icon {
  width: 25px;
  height: 25px;
  color: white;
}
.shopping-cart-icon.discounted {
  width: 15px;
  height: 15px;
  color: white;
}
@media only screen and (max-width: 569px) {
  .add-to-cart {
    height: 35px;
  }
  .product-items__count {
    display: none;
  }
}
@media only screen and (max-width: 510px) {
  .add-to-cart {
    font-size: 13px;
    height: 30px;
  }
  .shopping-cart-icon {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (max-width: 435px) {
  .add-to-cart {
    font-size: 12px;
    height: 25px;
    padding-top: 3px;
  }
  .shopping-cart-icon {
    width: 15px;
    height: 15px;
  }
  .add-to-cart-icon {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 416px) {
  .shopping-cart-icon {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (max-width: 404px) {
  .add-to-cart {
    font-size: 11px;
    height: 25px;
    padding-top: 3px;
  }

  .shopping-cart-icon {
    margin-left: -5px;
  }
}
@media only screen and (max-width: 380px) {
  .add-to-cart {
    font-size: 10px;
    height: 22px;
  }
  .shopping-cart-icon {
    margin-left: -7px;
  }
}
@media only screen and (max-width: 371px) {
  .add-to-cart {
    height: 22px;
  }
  .shopping-cart-icon {
    margin-left: -5px;
  }
}
@media only screen and (max-width: 363px) {
  .shopping-cart-icon {
    width: 11px;
    height: 11px;
    margin-left: -3px;
  }

  .product-items__price {
    font-size: 13px;
  }
}

@media only screen and (max-width: 354px) {
  .add-to-cart {
    height: 21px;
    font-size: 9px;
  }
}

.product-items__oldPrice {
  text-decoration: line-through;
}
