.cart-block {
  display: flex;
  align-items: center;
  position: relative;
}

.cart-price {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 17px;
  width: 60px;
}

.cart-icon {
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 512px) {
  .cart-price {
    margin-left: 3px;
    font-size: 15px;
    width: 60px;
  }
}
