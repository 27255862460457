/* Основной контейнер */
.climbing-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin: 0;
    padding: 0;
    width: 100%;
  }

  
  /* Контентный блок */
  .climbing-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 100px;
    width: 100%;
    /* padding-left: 20px;  */
  }
  
  /* Видео */
  .climbing-video {
    width: 460px;
    height: 355px;  
    display: flex;
    flex-direction: row;
 
  }
  
  /* Детали */
  .climbing-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 900px;
margin-top: 40px;
 
  }
  
  /* Текст */
  .climbing-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  
  /* Кнопка */
  .climbing-button {
    display: inline-block;
    background-color: #f68632;
    color: #fff;
    text-decoration: none;
    padding: 10px 25px;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
    transition: background-color 0.3s ease;
    
  }
  
  .climbing-button:hover {
    background-color: #d76f3b;
  }
  
  /* Ссылка */
  .climbing-link {
    font-size: 16px;
    color: #c35b28;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .climbing-link:hover {
    color: #d76f3b;
  }
  
  /* Адаптивность для мобильных устройств */
 /* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .climbing-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  
    .climbing-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Центровка всех элементов по горизонтали */
      justify-content: center; /* Центровка всех элементов по вертикали */
      gap: 20px; /* Добавим промежуток между элементами */
      width: 100%;
      text-align: center;
    }
  
    .climbing-video {
      width: 90%; /* Видео занимает почти всю ширину экрана */
      max-width: 460px; /* Ограничим максимальную ширину */
      height: auto; /* Соотношение сторон сохранится */
    }
  
    .climbing-details {
      margin: 0;
      padding: 0 20px;
      max-width: 90%;
    }
  
    .climbing-text {
      font-size: 14px;
      margin-bottom: 16px;
    }
  
    .climbing-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  
    .climbing-link {
      font-size: 14px;
    }
  }
  