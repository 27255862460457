.container-form {
  max-width: 400px;
  /* margin: 0 auto; */
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;

}

.title-form {
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
}

.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

label {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
}

/* input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box; 
} */


.input, .input-text {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box; 
 
}

.input-text {
  min-height: 80px;
  resize: vertical;
}

.input-error {
  font-size: 12px;
  color: red;
  margin-top: 4px;
}

.button-form {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background: #f68632;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-form:hover {
  background: #f99447;
}

@media (max-width: 480px) {
  .container-form {
    padding: 16px;
  }

  .button-form {
    font-size: 14px;
  }
}


