.rent-items {
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
  text-align: center;
  border: solid 2px #f5f5f5f5;
  height: 480px;
}




.rent-items__img {
  width: 80%;
  background-size: cover;
  background-position: center;
}

.rent-items__title {
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
}

.rent-items-day {
  padding-left: 10px;
}

.icon-search {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 500ms ease;
}

.icon-search:hover {
  transform: scale(1.1);
}

.icon-background {
  opacity: 0;
  width: 100%;
  height: 65%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 500ms ease;
}

.icon-background:hover {
  opacity: 1;
}

@media only screen and (max-width: 1123px) {
  .rent-items {
    height: 460px;
  }
}
@media only screen and (max-width: 1003px) {
  .rent-items {
    height: 440px;
  }
}
@media only screen and (max-width: 1003px) {
  .rent-items {
    height: 440px;
  }
}
@media only screen and (max-width: 935px) {
  .rent-items {
    height: 420px;
  }
}
@media only screen and (max-width: 900px) {
  .rent-items {
    height: 475px;
    width: 90%;
  }
}
@media only screen and (max-width: 850px) {
  .rent-items {
    height: 460px;
    width: 90%;
  }
}
@media only screen and (max-width: 775px) {
  .rent-items {
    height: 440px;
    width: 90%;
  }
}
@media only screen and (max-width: 725px) {
  .rent-items {
    height: 430px;
    width: 90%;
  }
}
@media only screen and (max-width: 670px) {
  .rent-items {
    height: 405px;
    width: 90%;
  }
  .rent-items__title {
    font-size: 14px;
  }
  .rent-items__desc {
    font-size: 14px;
  }
}
@media only screen and (max-width: 630px) {
  .rent-items {
    height: 390px;
  }
}
@media only screen and (max-width: 569px) {
  .rent-items {
    height: 370px;
  }
}
@media only screen and (max-width: 522px) {
  .rent-items {
    height: 350px;
  }
}
@media only screen and (max-width: 437px) {
  .rent-items {
    height: 325px;
  }
  .rent-items__title {
    font-size: 13px;
  }
  .rent-items__desc {
    font-size: 13px;
  }
}
@media only screen and (max-width: 414px) {
  .rent-items {
    height: 295px;
  }
  .rent-items__title {
    font-size: 12px;
  }
  .rent-items__desc {
    font-size: 12px;
  }
}
@media only screen and (max-width: 396px) {
  .rent-items {
    height: 300px;
  }
}
@media only screen and (max-width: 370px) {
  .rent-items {
    height: 280px;
  }
  .rent-items__title {
    font-size: 11px;
  }
  .rent-items__desc {
    font-size: 11px;
  }
}

@media only screen and (max-width: 356px) {
  .rent-items {
    height: 270px;
  }
}
